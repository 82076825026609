
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Route} from 'vue-router';

    import {LayoutModule, OrganizationModule} from '@/store/modules';

    import PublicHome from '@/components/PublicHome.vue';
    import PrivateHome from '@/components/PrivateHome.vue';

    const accountNamespace = namespace('account');

    @Component({
        components: {
            PublicHome,
            PrivateHome,
        },
        beforeRouteEnter(to: Route, from: Route, next: any) {
            next((vm: Home) => {
                const organizationModule = getModule(OrganizationModule, vm.$store);
                const layoutModule = getModule(LayoutModule, vm.$store);

                if (
                    organizationModule.loggedOrganization &&
                    organizationModule.loggedOrganization.status === 'enabled' &&
                    vm.$vuetify.breakpoint.mdAndUp
                ) {
                    layoutModule.setDrawerLeftOpened(true);
                }
            });
        },
    })
    export default class Home extends Vue {
        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;
    }
