
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';

    import {
        IAgenda,
        IEventRangeForm,
        IOrganization,
        IUnavailabilityForm,
    } from '@/types';

    import {formHelper} from '@/helpers';

    import EventRangeForm from '@/components/forms/EventRangeForm.vue';
    import RecurrenceFormCard from '@/components/RecurrenceFormCard.vue';

    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');

    @Component<UnavailabilityForm>({
        components: {
            EventRangeForm,
            RecurrenceFormCard,
        },
    })
    export default class UnavailabilityForm extends Vue {
        public unavailabilityFormValid: boolean = false;
        public rangeFormValid: boolean = false;

        public agendaRules = formHelper.getEventAgendaRules();
        public objectRules = formHelper.getUnavailabilityObjectRules();
        public notesRules = formHelper.getUnavailabilityNoteRules();

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Prop({
            type: Object,
            required: true,
        })
        public value!: IUnavailabilityForm;

        get range(): IEventRangeForm {
            return {
                start: this.value.start,
                end: this.value.end,
                recurrence: this.value.recurrence,
            };
        }

        set range(value: IEventRangeForm) {
            this.value = Object.assign(this.value, value);
        }

        public updateRangeFormValidity(value: boolean) {
            this.rangeFormValid = value;
            this.onValidityChanged();
        }

        public onValidityChanged() {
            this.$emit('valid', this.unavailabilityFormValid && this.rangeFormValid);
        }

        public created() {
            if (!this.value.object) {
                this.value.object = 'Indisponibilité';
            }
        }
    }
