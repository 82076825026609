
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model, Prop, Watch} from 'vue-property-decorator';
    import {DateTime} from 'luxon';

    import {formHelper, rruleHelper} from '@/helpers';

    import {
        IRecurrence,
    } from '@/types';

    @Component<RecurrenceFormCard>({})
    export default class RecurrenceFormCard extends Vue {
        public recurrenceFormValid: boolean = false;

        public untilMenu: boolean = false;

        public frequency: 'DAILY' | 'WEEKLY' = 'WEEKLY';
        public interval: number = 1;
        public byDay: string[] = [];
        public isInfinite: boolean = true;
        public stopCondition: string = 'UNTIL';
        public until: string|null = null;
        public count: number = 3;
        public exDate: string[] = [];

        public innerModel!: string;

        public frequencyRules = formHelper.getRecurrenceFrequencyRules();
        public intervalRules = formHelper.getRecurrenceIntervalRules();
        public byDayRules = formHelper.getRecurrenceByDayRules();
        public stopConditionRules = formHelper.getRecurrenceStopConditionRules();
        public untilRules = [
            ...formHelper.getRecurrenceUntilRules(),
            (v: string) => this.isAfterStart() || 'La date de fin doit être postérieure au début',
        ];
        public countRules = formHelper.getRecurrenceCountRules();

        public frequencies = [
            {
                value: 'DAILY',
                text: 'Jour',
            },
            {
                value: 'WEEKLY',
                text: 'Semaine',
            },
        ];

        public days = [
            {
                value: 'MO',
                text: 'Lundi',
            },
            {
                value: 'TU',
                text: 'Mardi',
            },
            {
                value: 'WE',
                text: 'Mercredi',
            },
            {
                value: 'TH',
                text: 'Jeudi',
            },
            {
                value: 'FR',
                text: 'Vendredi',
            },
            {
                value: 'SA',
                text: 'Samedi',
            },
            {
                value: 'SU',
                text: 'Dimanche',
            },
        ];

        public stopConditions = [
            {
                value: 'COUNT',
                text: 'Un nombre de répétitions',
            },
            {
                value: 'UNTIL',
                text: 'Une date précise',
            },
        ];

        @Model('input', {type: String}) public readonly value!: string;

        @Prop({
            type: String,
            required: true,
        })
        public start!: string;

        @Prop({
            type: String,
            required: false,
        })
        public forcedFrequency!: 'DAILY' | 'WEEKLY';

        @Watch('start')
        public onStartChanged(val: string, oldVal: string) {
            this.byDay = rruleHelper.getByDayAfterStartDateChanged(val, oldVal, this.recurrenceObject);
            const untilField = this.$refs.untilField;

            if (untilField) {
                (untilField as HTMLFormElement).validate();
            }
            this.$emit('input', this.recurrenceRule);
        }

        get untilFormatted() {
            if (!this.until) {
                return null;
            }

            return DateTime.fromISO(this.until).toLocaleString(DateTime.DATE_SHORT);
        }

        set untilFormatted(value: string | null) {
            if (!value) {
                this.until = null;
            }
        }

        get recurrenceObject() {
            const recurrence: IRecurrence = {
                frequency: this.frequency,
                interval: this.interval,
                byDay: this.byDay,
                exDate: this.exDate,
            };

            if (!this.isInfinite) {
                if (this.stopCondition === 'UNTIL' && this.until) {
                    const formatOpts = {
                        suppressMilliseconds: true,
                    };

                    recurrence.until = DateTime
                        .fromISO(this.until)
                        .endOf('day')
                        .set({millisecond: 0})
                        .toUTC()
                        .toISO(formatOpts) as string
                    ;
                } else if (this.stopCondition === 'COUNT' && this.count) {
                    recurrence.count = this.count;
                }
            }

            return recurrence;
        }

        get recurrenceRule() {
            return rruleHelper.getRecurrenceRule(this.start, this.recurrenceObject);
        }

        get recurrenceRuleText() {
            if (!this.recurrenceRule) {
                return null;
            }

            return rruleHelper.getTextRepresentation(this.recurrenceRule);
        }

        public isAfterStart() {
            const until = DateTime.fromISO(`${this.until}T23:59:59`).toUTC();
            const start = DateTime.fromISO(this.start);

            return until >= start;
        }

        public submitButtonClicked() {
            this.$emit('input', this.recurrenceRule);
            this.$emit('close');
        }

        public backButtonClicked() {
            return this.$emit('close');
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
            };
        }

        private created() {
            if (this.value) {
                const parsed = rruleHelper.parseRule(this.value);

                this.frequency = parsed.frequency;
                this.interval = parsed.interval;
                this.byDay = parsed.byDay;
                this.exDate = parsed.exDate;

                if (parsed.until) {
                    this.until = DateTime.fromISO(parsed.until).toISODate();
                    this.stopCondition = 'UNTIL';
                    this.isInfinite = false;
                } else if (parsed.count) {
                    this.count = parsed.count;
                    this.stopCondition = 'COUNT';
                    this.isInfinite = false;
                }

                if (!this.until) {
                    this.until = DateTime
                        .fromISO(this.start)
                        .toISODate()
                    ;
                }
            } else if (this.start) {
                const localStart = DateTime.fromISO(this.start);

                this.byDay = [
                    localStart.setLocale('en-gb').toLocaleString({ weekday: 'long' }).substring(0, 2).toUpperCase(),
                ];
                this.until = localStart.toISODate();
            }

            if (this.forcedFrequency) {
                this.frequency = this.forcedFrequency;
            }
        }
    }
