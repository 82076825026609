
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Model, Prop, Watch} from 'vue-property-decorator';

@Component<IbanInput>({})
export default class IbanInput extends Vue {
    public innerModel: stripe.elements.Element|null = null;

    @Prop({
        type: Object,
        required: true,
    })
    public stripeModule!: stripe.Stripe;

    @Model('stripeIban', {type: Object})
    public readonly value!: stripe.elements.Element|null;

    public createStripeIbanField() {
        const elements = this.stripeModule.elements();
        const errorColor: string = this.$vuetify.theme.currentTheme.error as string;

        const style = {
            base: {
                'color': 'rgba(0,0,0,0.87)',
                'fontFamily': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                'fontSmoothing': 'antialiased',
                'fontSize': this.$vuetify.breakpoint.xsOnly ? '13px' : '16px',
                '::placeholder': {
                    color: 'rgba(0,0,0,0.6)',
                },
                ':-webkit-autofill': {
                    color: 'rgba(0,0,0,0.6)',
                },
            },
            invalid: {
                'color': errorColor,
                'iconColor': errorColor,
                ':-webkit-autofill': {
                    color: errorColor,
                },
            },
        };

        const options = {
            style,
            supportedCountries: ['SEPA'],
            placeholderCountry: 'FR',
        };

        this.innerModel = elements.create('iban', options);
        this.innerModel.mount('#iban-element');
        const el = document.querySelector<HTMLElement>('#iban-element iframe');
        if (el) {
            el.style.width = '';
        }
        this.onIbanCreated();

        this.innerModel.on('change', (event: any) => {
            this.onIbanChange(event);
        });
    }

    public mounted() {
        this.createStripeIbanField();
    }

    @Emit('stripeIban')
    private onIbanCreated() {
        return this.innerModel;
    }

    @Emit('change')
    private onIbanChange(event: any) {
        return event;
    }

}
