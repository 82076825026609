
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAgenda,
        IEventRangeForm,
        IOrganization,
        ICreateBusinessHourParams,
        IUpdateBusinessHourParams,
        IBusinessHour,
        IReason,
    } from '@/types';

    import {
        SnackbarModule,
        BusinessHourModule,
    } from '@/store/modules';

    import {formHelper} from '@/helpers';

    import EventRangeForm from '@/components/forms/EventRangeForm.vue';
    import RecurrenceFormCard from '@/components/RecurrenceFormCard.vue';

    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');

    @Component<BusinessHourFormCard>({
        components: {
            EventRangeForm,
            RecurrenceFormCard,
        },
    })
    export default class BusinessHourFormCard extends Vue {
        public isSubmittingBusinessHour: boolean = false;

        public businessHourFormValid: boolean = false;
        public rangeFormValid: boolean = false;

        public innerModel!: IBusinessHour;

        public agendaRules = formHelper.getEventAgendaRules();

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('organizationReasonsEnabledList')
        public organizationReasonsEnabledList!: IReason;

        @Model('input', {type: Object}) public readonly value!: IBusinessHour;

        get range(): IEventRangeForm {
            return {
                start: this.innerModel.start,
                end: this.innerModel.end,
                recurrence: this.innerModel.recurrence,
            };
        }

        set range(value: IEventRangeForm) {
            this.innerModel = Object.assign(this.innerModel, value);
        }

        get cardTitle(): string {
            if (this.innerModel.id) {
                return 'Modifier des horaires d\'ouverture';
            }

            return 'Ajouter des horaires d\'ouverture';
        }

        get submitButtonText(): string {
            if (this.innerModel.id) {
                return 'Enregistrer';
            }

            return 'Confirmer';
        }

        get params(): ICreateBusinessHourParams|IUpdateBusinessHourParams {
            const params: ICreateBusinessHourParams|IUpdateBusinessHourParams = {
                organization_id: this.loggedOrganization.id,
                agenda_id: this.innerModel.agenda.id,
                reasons: this.innerModel.reasons.map((reason: IReason) => {
                    return reason.id;
                }),
            };

            if (this.innerModel.recurrence) {
                const start = DateTime.fromISO(this.innerModel.start);
                const end = DateTime.fromISO(this.innerModel.end);

                params.recurrence = this.innerModel.recurrence;
                params.duration = end.diff(start).milliseconds;
            } else {
                params.start = this.innerModel.start;
                params.end = this.innerModel.end;
            }

            if (this.innerModel.id) {
                (params as IUpdateBusinessHourParams).business_hour_id = this.innerModel.id;
            }

            return params;
        }

        public submitButtonClicked() {
            if (this.innerModel.id) {
                return this.updateBusinessHour();
            }

            return this.createBusinessHour();
        }

        public createBusinessHour() {
            this.isSubmittingBusinessHour = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(BusinessHourModule, this.$store)
                .createBusinessHour(this.params as ICreateBusinessHourParams)
                .then((businessHour: IBusinessHour) => {
                    snackbarModule.displaySuccess('Vos horaires d\'ouverture ont bien été ajoutés !');
                    this.$emit('input', businessHour);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => this.isSubmittingBusinessHour = false)
            ;
        }

        public updateBusinessHour() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingBusinessHour = true;

            getModule(BusinessHourModule, this.$store)
                .updateBusinessHour(this.params as IUpdateBusinessHourParams)
                .then((businessHour: IBusinessHour) => {
                    snackbarModule.displaySuccess('Vos horaires d\'ouverture ont bien été modifiés !');
                    this.$emit('input', businessHour);
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingBusinessHour = false)
            ;
        }

        private data() {
            return {
                innerModel: JSON.parse(JSON.stringify(this.value)),
            };
        }
    }
