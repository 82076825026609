
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';

    import {
        IAccountRole,
        IAgenda,
        IOrganization,
    } from '@/types';

    import Agenda from '@/components/Agenda.vue';
    import LinkOrganization from '@/components/LinkOrganization.vue';
    import SelectOrganization from '@/components/SelectOrganization.vue';

    const accountNamespace = namespace('account');
    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');

    @Component<PrivateHome>({
        components: {
            Agenda,
            LinkOrganization,
            SelectOrganization,
        },
    })
    export default class PrivateHome extends Vue {
        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];
    }
