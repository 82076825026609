
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAccountRole,
        IAgenda,
        IOrganization,
        IOrganizationJoinRequest,
        ISearchParams,
    } from '@/types';

    import {
        AgendaModule,
        ReasonModule,
    } from '@/store/modules';

    import AccountJoinRequests from '@/components/AccountJoinRequests.vue';
    import Agenda from '@/components/Agenda.vue';
    import AgendaWelcome from '@/components/AgendaWelcome.vue';
    import CreateSubscription from '@/components/link-organization/CreateSubscription.vue';
    import LinkOrganization from '@/components/LinkOrganization.vue';
    import SelectOrganization from '@/components/SelectOrganization.vue';

    const accountNamespace = namespace('account');
    const agendaNamespace = namespace('agenda');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');

    @Component<PrivateHome>({
        components: {
            AccountJoinRequests,
            Agenda,
            AgendaWelcome,
            CreateSubscription,
            LinkOrganization,
            SelectOrganization,
        },
    })
    export default class PrivateHome extends Vue {
        public isLoading = false;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @accountNamespace.Getter('loggedAccountInvitations')
        public loggedAccountInvitations!: IOrganizationJoinRequest[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @agendaNamespace.Getter('selectedAgendasList')
        public selectedAgendasList!: IAgenda[];

        @agendaNamespace.Action('selectAllAgendas')
        public selectAllAgendas!: () => void;

        @agendaNamespace.Action('selectAgendas')
        public selectAgendas!: (agendas: string[]) => void;

        @reasonNamespace.Action('selectAllReasons')
        public selectAllReasons!: () => void;

        get mode() {
            if (this.loggedAccountInvitations.length > 0) {
                return 'account-invitations';
            }

            if (this.loggedAccountRoles.length === 0) {
                return 'link-organization';
            }

            if (this.loggedOrganization && this.loggedOrganization.status === 'pending_subscription') {
                return 'create-subscription';
            }

            if (!this.loggedOrganization) {
                return 'select-organization';
            }

            return 'agenda';
        }

        public mounted() {
            setTimeout(()=> {
                if (this.mode === 'agenda') {
                    this.fetchAgendasAndReasons();
                }
            },1000)
        }
        private fetchAgendasAndReasons() {
            this.isLoading = true;

            const agendaModule = getModule(AgendaModule, this.$store);
            const reasonModule = getModule(ReasonModule, this.$store);
            const params: ISearchParams = {
                organization_id: this.loggedOrganization.id,
                per_page: 200,
            };

            Promise
                .all([
                    agendaModule.fetchAgendas(this.loggedOrganization.id),
                    reasonModule.fetchOrganizationReasonsEnabled(params),
                ])
                .then(() => {
                    this.selectAllReasons();
                    if (this.selectedAgendasList.length === 0) { 
                        this.selectAllAgendas() } 
                    else { 
                        const filteredIds: string[] = this.selectedAgendasList.map(agenda => agenda.id).filter((id): id is string => typeof id === 'string');
                        this.selectAgendas(filteredIds)
                    };
                    this.isLoading = false;
                })
            ;
        }
    }
