
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Emit, Model, Watch, Prop} from 'vue-property-decorator';
    import {debounce} from 'debounce';

    import {IPlaceSuggestion} from '@/types';

    @Component<PlaceInput>({})
    export default class PlaceInput extends Vue {
        public isLoading: boolean = false;
        public items: IPlaceSuggestion[] = [];
        public searchText: string|null = null;
        public innerModel: IPlaceSuggestion|null = null;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public fromTunnel!: boolean;

        @Prop({
            type: Boolean,
            default: false,
        })
        public readonly!: boolean;

        @Model('input', {type: Object}) public readonly value!: IPlaceSuggestion|null;

        private debouncedAutocompleteQuery = debounce(this.sendAutocompleteQuery, 300);

        public get search() {
            return this.searchText ?
                this.searchText :
                this.innerModel ?
                    this.innerModel.title :
                    ''
            ;
        }

        public set search(val: string) {
            if (val) {
                this.searchText = val;
            } else {
                this.searchText = (this.innerModel ? this.innerModel.title : null);
            }
        }

        @Watch('value', {immediate: true})
        private valueChanged(val: IPlaceSuggestion|null) {
            if (val !== this.innerModel) {
                this.innerModel = val;
            }
        }

        @Watch('searchText')
        private onSearchTextChange(val: string) {
            if (!val) {
                this.items = [];
                return;
            }

            this.debouncedAutocompleteQuery(val);
        }

        @Emit('input')
        private onAddressChange() {
            return this.innerModel;
        }

        private sendAutocompleteQuery(val: string) {
            this.isLoading = true;

            return this.$api.place
                .placeAutocomplete(val)
                .then((suggestions: IPlaceSuggestion[]) => {
                    this.items = suggestions;
                })
                .finally(() => this.isLoading = false)
            ;
        }
    }
