
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model, Prop, Watch} from 'vue-property-decorator';
    import {DateTime, ToISOTimeOptions} from 'luxon';

    import {
        IEventRangeForm,
    } from '@/types';

    import {formHelper, rruleHelper} from '@/helpers';

    import RecurrenceFormCard from '@/components/RecurrenceFormCard.vue';

    @Component<EventRangeForm>({
        components: {
            RecurrenceFormCard,
        },
    })
    export default class EventRangeForm extends Vue {
        private static extractDateFromString(value: string) {
            return DateTime
                .fromISO(value)
                .toISODate()
                ;
        }

        private static extractTimeFromString(value: string) {
            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
                suppressSeconds: true,
                includeOffset: false,
            };

            return DateTime
                .fromISO(value)
                .toISOTime(formatOpts)
                ;
        }

        public rangeFormValid: boolean = false;

        public isAllDay: boolean = false;

        public hasRecurrence = false;
        public initialeRecurrence: string|null = null;
        public recurrenceDialog: boolean = false;

        public startDateMenu: boolean = false;
        public endDateMenu: boolean = false;

        public startDate: string|null = null;
        public endDate: string|null = null;
        public startTime: string|null = null;
        public endTime: string|null = null;

        public innerModel!: IEventRangeForm;

        public startDateRules = formHelper.getEventStartDateRules();
        public startTimeRules = formHelper.getEventStartTimeRules();

        public endDateRules = [
            ...formHelper.getEventEndDateRules(),
            (v: string) => this.validateEndDate(),
        ];

        public endTimeRules = [
            ...formHelper.getEventEndTimeRules(),
            (v: string) => this.validateEndTime(),
        ];

        public recurrenceRules = [
            (arg: string) => !!arg || 'Veuillez définir la répétition ou la désactiver',
        ];

        @Model('input', {type: Object}) public readonly value!: IEventRangeForm;

        @Prop({
            type: String,
            required: true,
        })
        public type!: string;

        @Prop({
            type: String,
            required: false,
        })
        public forcedFrequency!: string;

        @Watch('innerModel', {deep: true})
        public onInnerModelChanged(val: IEventRangeForm) {
            this.$emit('input', val);
        }

        get switchLabel() {
            switch (this.type) {
                case 'business-hour':
                    return 'Répéter ces horaires d\'ouverture';
                case 'unavailability':
                    return 'Répéter cette indisponibilité';
                default:
                    return '';
            }
        }

        get startDateFormatted() {
            if (!this.startDate) {
                return null;
            }

            return DateTime.fromISO(this.startDate).toLocaleString(DateTime.DATE_SHORT);
        }

        set startDateFormatted(value: string | null) {
            if (!value) {
                this.startDate = null;
            }
        }

        get endDateFormatted() {
            if (!this.endDate) {
                return null;
            }

            return DateTime.fromISO(this.endDate).toLocaleString(DateTime.DATE_SHORT);
        }

        set endDateFormatted(value:  string | null) {
            if (!value) {
                this.endDate = null;
            }
        }

        get endTimeMin() {
            if (!this.hasRecurrence && this.startDate !== this.endDate) {
                return null;
            }

            return this.startTime;
        }

        get recurrenceText() {
            if (!this.innerModel.recurrence) {
                return null;
            }

            return rruleHelper.getTextRepresentation(this.innerModel.recurrence as string);
        }

        private validateEndTime() {
            if (!this.endTimeMin) {
                return true;
            }

            if (!this.startTime || !this.endTime) {
                return true;
            }

            return this.startTime < this.endTime || 'Doit être supérieure à l\'heure de début';
        }

        private validateEndDate() {
            if (!this.startDate || !this.endDate) {
                return true;
            }

            return this.startDate <= this.endDate || 'Doit être supérieure au jour de début';
        }

        private onHasRecurrenceChange() {
            if (!this.hasRecurrence) {
                this.innerModel.recurrence = null;
            } else {
                if (this.initialeRecurrence) {
                    this.innerModel.recurrence = this.initialeRecurrence;
                }

                this.recurrenceDialog = true;
            }

            this.updateStartAndEnd();
            this.revalidateEndDateAndTime();
        }

        private onIsAllDayChange() {
            if (this.isAllDay) {
                this.startTime = '00:00';
                this.endDate = DateTime.fromISO(this.startDate as string).plus({day: 1}).toISODate();
                this.endTime = '00:00';

                this.updateStartAndEnd();
            } else {
                this.startTime = '00:00';
                this.endDate = this.startDate;
                this.endTime = '23:59';

                this.updateStartAndEnd();
            }
        }

        private onStartDateChange(val: string, oldVal: string) {
            this.updateStartAndEnd();
            this.startDateMenu = false;
        }

        private onEndDateChange() {
            this.updateStartAndEnd();
            this.endDateMenu = false;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.value),
                initialeRecurrence: this.value.recurrence,
            };
        }

        private mounted() {
            if (this.innerModel.start) {
                this.startDate = EventRangeForm.extractDateFromString(this.innerModel.start);
                this.startTime = EventRangeForm.extractTimeFromString(this.innerModel.start);
            }

            if (this.innerModel.end) {
                this.endDate = EventRangeForm.extractDateFromString(this.innerModel.end);
                this.endTime = EventRangeForm.extractTimeFromString(this.innerModel.end);
            }

            this.isAllDay =
                this.type === 'unavailability' &&
                this.startTime === '00:00' &&
                this.endTime === '00:00' &&
                DateTime.fromISO(this.startDate as string).plus({day: 1}).toISODate() === this.endDate
            ;

            this.hasRecurrence = !!this.innerModel.recurrence;
        }

        private revalidateEndDateAndTime() {
            if (!this.isAllDay) {
                this.$nextTick(() => {
                    (this.$refs.endTime as HTMLFormElement).validate();

                    if (!this.hasRecurrence) {
                        (this.$refs.endDate as HTMLFormElement).validate();
                    }
                });
            }
        }

        private updateStartAndEnd() {
            this.updateStart();
            this.updateEnd();
            this.revalidateEndDateAndTime();
        }

        private updateStart() {
            const timeValid = this.startTime && /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this.startTime);
            const dateValid = this.startDate && DateTime.fromISO(this.startDate).isValid;
            const oldStart = this.innerModel.start;

            let start = null;

            if (timeValid && dateValid) {
                start = DateTime
                    .fromISO(`${this.startDate}T${this.startTime}:00`)
                    .toUTC()
                    .toISO({suppressMilliseconds: true})
                ;
            }

            (this.innerModel.start as string | null) = start;

            // Update recurrence rule if the recurrence card has never been created
            if (this.hasRecurrence && this.innerModel.recurrence &&  !this.$refs.recurrenceFormCard && start) {
                const recurrenceRule = rruleHelper.parseRule(this.innerModel.recurrence as string);
                recurrenceRule.byDay = rruleHelper.getByDayAfterStartDateChanged(start, oldStart, recurrenceRule);

                this.innerModel.recurrence = rruleHelper.getRecurrenceRule(
                    this.innerModel.start,
                    recurrenceRule,
                );
            }
        }

        private updateEnd() {
            let end = null;

            if (this.isAllDay) {
                const endDate = DateTime.fromISO(this.startDate as string).plus({day: 1}).toISODate();

                end = DateTime
                    .fromISO(`${endDate}T00:00:00`)
                    .toUTC()
                    .toISO({suppressMilliseconds: true})
                ;
            } else if (this.endTime && /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this.endTime)) {
                const endDate = this.hasRecurrence ? this.startDate : this.endDate;

                if (endDate && DateTime.fromISO(endDate).isValid) {
                    end = DateTime
                        .fromISO(`${endDate}T${this.endTime}:00`)
                        .toUTC()
                        .toISO({suppressMilliseconds: true})
                    ;
                }
            }

            (this.innerModel.end as string | null) = end;
        }
    }
