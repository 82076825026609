
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';

    import {
        IRegistration,
    } from '@/types';

    import {formHelper} from '@/helpers';

    import {
        RegistrationModule,
    } from '@/store/modules';

    import PhoneInput from '@/components/forms/PhoneInput.vue';
    import TheFooter from '@/components/layout/TheFooter.vue';

    @Component<PublicHome>({
        components: {
            PhoneInput,
            TheFooter,
        },
    })
    export default class PublicHome extends Vue {
        public homeFormIsValid: boolean = false;
        public innerModel: IRegistration = {
            lastName: '',
            firstName: '',
            email: '',
            emailConfirmation: '',
            phone: '',
            postalCode: '',
        };
        public type: string|null = null;
        public loading: boolean = false;

        public firstNameRules = formHelper.getFirstNameRules();
        public lastNameRules = formHelper.getLastNameRules();
        public emailRules = formHelper.getEmailsRules();
        public postalCodeRules = [
            (v: number) => !!v || 'Code postal de l\'établissement',
        ];
        public typeRules = [
            (v: string) => !!v || 'Indiquer votre statut',
        ];

        private invitationEmail: string|null = null;

        private types = [
            {
                value: 'pro',
                title: 'Je suis professionnel de santé animale',
                subtitle: 'Je souhaite m\'inscrire sur Vetolib',
            },
            {
                value: 'client',
                title: 'Je suis propriétaire d\'un animal',
                subtitle: 'Je souhaite prendre RDV chez un vétérinaire',
            },
        ];

        public goToRegisterTunnel() {
            getModule(RegistrationModule, this.$store)
                .setRegistrationData({
                    lastName: this.innerModel.lastName,
                    firstName: this.innerModel.firstName,
                    email: this.innerModel.email,
                    emailConfirmation: (this.innerModel.email === this.invitationEmail) ?
                        this.invitationEmail : '',
                    phone: this.innerModel.phone,
                    postalCode: this.innerModel.postalCode,
                });
            this.$router.push({name: 'register'});
        }
    }
