
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime, DateTimeFormatOptions, LocaleOptions} from 'luxon';

    import {EventApi} from '@fullcalendar/common';

    import {
        IAgenda,
        IEvent,
        IOrganization,
        IUnavailability,
        IUpdateUnavailabilityParams,
        IUnavailabilityForm,
    } from '@/types';

    import DeleteUnavailabilityDialog from '@/components/dialogs/DeleteUnavailabilityDialog.vue';
    import UnavailabilityForm from '@/components/UnavailabilityForm.vue';

    import {rruleHelper} from '@/helpers';

    import {EventModule, SnackbarModule} from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<UnavailabilityCard>({
        components: {
            DeleteUnavailabilityDialog,
            UnavailabilityForm,
        },
    })
    export default class UnavailabilityCard extends Vue {
        public deleteDialog: boolean = false;

        public unavailabilityToEdit!: IUnavailabilityForm;
        public unavailabilityFormIsOpen: boolean = false;
        public unavailabilityFormValid: boolean = false;
        public isSubmittingUnavailabity: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public value!: EventApi;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get agenda() {
            return this.unavailability.agenda.name;
        }

        get startDateTimeObject() {
            return DateTime.fromJSDate(this.value.start as Date, {zone: this.unavailability.timezone});
        }

        get endDateTimeObject() {
            return DateTime.fromJSDate(this.value.end as Date, {zone: this.unavailability.timezone});
        }

        get startDate() {
            return this.startDateTimeObject.toLocaleString({weekday: 'long', day: 'numeric', month: 'long'});
        }

        get endDate() {
            return this.endDateTimeObject.toLocaleString({weekday: 'long', day: 'numeric', month: 'long'});
        }

        get startTime() {
            return this.startDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get endTime() {
            return this.endDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get startDateTime() {
            const formatOpts: LocaleOptions & DateTimeFormatOptions = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: '2-digit',
            };

            return this.startDateTimeObject.toLocaleString(formatOpts);
        }

        get endDateTime() {
            const formatOpts: LocaleOptions & DateTimeFormatOptions = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: '2-digit',
            };

            return this.endDateTimeObject.toLocaleString(formatOpts);
        }

        get unavailability(): IUnavailability {
            return this.value.extendedProps.event;
        }

        // // tslint:disable-next-line:no-empty
        // set unavailability(val) {}

        get isAllDay(): boolean {
            const startPlusOneDay = this.startDateTimeObject.plus({day: 1});

            return this.startTime === '00:00' &&
                this.endTime === '00:00' &&
                startPlusOneDay.toMillis() === this.endDateTimeObject.toMillis()
            ;
        }

        get object() {
            return this.unavailability.unavailability.object;
        }

        get note() {
            return this.unavailability.unavailability.comment;
        }

        get recurrenceText() {
            if (!this.unavailability.recurrence) {
                return null;
            }

            return rruleHelper.getTextRepresentation(this.unavailability.recurrence);
        }

        get unavailabilityParams(): IUpdateUnavailabilityParams {
            const params: IUpdateUnavailabilityParams = {
                unavailability_id: this.unavailability.unavailability.id,
                organization_id: this.loggedOrganization.id,
                agenda_id: (this.unavailabilityToEdit.agenda as IAgenda).id,
                object: this.unavailabilityToEdit.object,
                comment: this.unavailabilityToEdit.comment,
            };

            if (this.unavailabilityToEdit.recurrence) {
                const start = DateTime.fromISO(this.unavailabilityToEdit.start);
                const end = DateTime.fromISO(this.unavailabilityToEdit.end);
                const duration = end.diff(start).milliseconds;

                params.recurrence = this.unavailabilityToEdit.recurrence;
                params.duration = duration;
            } else {
                params.start = this.unavailabilityToEdit.start;
                params.end = this.unavailabilityToEdit.end;
            }

            return params;
        }

        public startEditing() {
            this.unavailabilityToEdit = {
                agenda: this.unavailability.agenda,
                object: this.unavailability.unavailability.object,
                comment: this.unavailability.unavailability.comment,
                start: this.unavailability.start,
                end: this.unavailability.end,
                recurrence: this.unavailability.recurrence,
                until: this.unavailability.until,
            };

            this.unavailabilityFormIsOpen = true;
        }

        public updateUnavailability() {
            this.isSubmittingUnavailabity = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(EventModule, this.$store)
                .updateUnavailability(this.unavailabilityParams)
                .then((unavailability: IEvent) => {
                    snackbarModule.displaySuccess('Votre indisponibilité a bien été modifiée !');
                    this.$emit('close');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingUnavailabity = false)
            ;
        }
    }
