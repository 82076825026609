
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class TheFooter extends Vue {
        private lists = [
            {
                title: 'Villes',
                links: [
                    {
                        title: 'Paris',
                        url: '',
                    },
                    {
                        title: 'Marseille',
                        url: '',
                    },
                    {
                        title: 'Lyon',
                        url: '',
                    },
                    {
                        title: 'Strasbourg',
                        url: '',
                    },
                ],
            },
            {
                title: 'Animaux',
                links: [
                    {
                        title: 'Chien',
                        url: '',
                    },
                    {
                        title: 'Chat',
                        url: '',
                    },
                    {
                        title: 'Chevaux',
                        url: '',
                    },
                    {
                        title: 'Oiseaux',
                        url: '',
                    },
                ],
            },
            {
                title: 'Spécialités',
                links: [
                    {
                        title: 'Chiens & Chats',
                        url: '',
                    },
                    {
                        title: 'NAC',
                        url: '',
                    },
                    {
                        title: 'Equidés',
                        url: '',
                    },
                ],
            },
        ];

        private bottomLinks = [
            {
                title: 'A propos',
                url: {name: 'about'},
            },
            {
                title: 'Contact',
                url: {name: 'contact'},
            },
            {
                title: 'CGV',
                url: {name: 'terms'},
            },
            {
                title: 'Politique de confidentialité',
                url: {name: 'rgpd'},
            },
        ];

        private externalLinks = [
            {
                title: 'L\'ordre des vétérinaires',
                url: 'https://www.veterinaire.fr/',
            },
            // {
            //     icon: 'mdi-facebook',
            //     url: 'https://www.facebook.com/trouveunveto/',
            // },
            // {
            //     icon: 'mdi-twitter',
            //     url: 'https://www.twitter.com/trouveunveto/',
            // },
        ];

        get showContactUs() {
            return this.$route.name !== 'contact';
        }
    }
