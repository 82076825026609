
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    import {
        IAccountRole,
        IOrganization,
    } from '@/types';

    const accountNamespace = namespace('account');

    @Component<SelectOrganization>({})
    export default class SelectOrganization extends Vue {
        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        get organizations() {
            return this.loggedAccountRoles
                .filter((accountRole: IAccountRole) => {
                    return !!accountRole.organization;
                })
                .map((accountRole: IAccountRole) => {
                    return accountRole.organization;
                })
            ;
        }

        public selectOrganization(organization: IOrganization) {
            getModule(OrganizationModule, this.$store).selectOrganization(organization);
            getModule(SnackbarModule, this.$store).displaySuccess(`Vous êtes bien connecté à ${organization.name}`);
        }

        public created() {
            if (this.organizations.length === 1) {
                getModule(OrganizationModule, this.$store).selectOrganization(this.organizations[0] as IOrganization);
            }
        }
    }
