export function slugify(val: string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return val.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
    ;
}

export function lightenDarkenColor(hex: string, percent: number) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1');
    }

    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    return '#' +
        // tslint:disable-next-line:no-bitwise
        ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
        // tslint:disable-next-line:no-bitwise
        ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
        // tslint:disable-next-line:no-bitwise
        ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1)
    ;
}

export function filterObject(obj: object, callback: (key: string, val: any) => boolean) {
    return Object.fromEntries(Object.entries(obj).
        filter(([key, val]) => callback(key, val)));
}
