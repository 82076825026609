
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';

    import {
        Calendar,
        Duration,
        EventApi,
        EventContentArg,
        EventInput,
        ViewApi,
    } from '@fullcalendar/core';

    import {
        ResourceApi,
    } from '@fullcalendar/resource';


    import {
        DateTime,
        DateTimeFormatOptions,
        Duration as LuxonDuration,
        LocaleOptions,
        ToISOTimeOptions,
    } from 'luxon';

    import {toLuxonDateTime} from '@fullcalendar/luxon';

    import luxonPlugin from '@fullcalendar/luxon';
    import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import listPlugin from '@fullcalendar/list';
    import rrulePlugin from '@fullcalendar/rrule';

    // @ts-ignore
    import frLocale from '@fullcalendar/core/locales/fr';

    import {
        AgendaViewValue,
        IAccountRole,
        IAgenda,
        IOrganization,
        IReason,
        IListEventsParams,
        IEvent,
        IBooking,
        IUnavailability,
        IAnimal,
        IListBusinessHoursParams,
        IBusinessHour,
        IDisplaySettings,
        IHospitalization,
        IBusinessHourToCreate,
        IEventToCreate,
        IEventTooltipStyle,
    } from '@/types';

    import {lightenDarkenColor} from '@/helpers/tools';

    import {rruleHelper} from '@/helpers';

    import {EventModule, SnackbarModule, BusinessHourModule} from '@/store/modules';

    import BookingCard from '@/components/BookingCard.vue';
    import BusinessHourCard from '@/components/BusinessHourCard.vue';
    import BusinessHourFormCard from '@/components/BusinessHourFormCard.vue';
    import CreateEventCard from '@/components/CreateEventCard.vue';
    import DisplaySettingsFormCard from '@/components/DisplaySettingsFormCard.vue';
    import UnavailabilityCard from '@/components/UnavailabilityCard.vue';

    const accountNamespace = namespace('account');
    const agendaNamespace = namespace('agenda');
    const businessHourNamespace = namespace('businessHour');
    const eventNamespace = namespace('event');
    const organizationNamespace = namespace('organization');
    const reasonNamespace = namespace('reason');

    @Component<Agenda>({
        components: {
            BookingCard,
            BusinessHourCard,
            BusinessHourFormCard,
            CreateEventCard,
            DisplaySettingsFormCard,
            UnavailabilityCard,
        },
    })
    export default class Agenda extends Vue {
        public calendar: Calendar | any;

        public mode: string = '';

        public createBusinessHourDialog: boolean = false;
        public createEventDialog: boolean = false;
        public businessHourDetailsDialog: boolean = false;
        public bookingDetailsDialog: boolean = false;
        public unavailabilityDetailsDialog: boolean = false;
        public displaySettingsDialog: boolean = false;
        public moveEventDialog: boolean = false;
        public resizeEventDialog: boolean = false;
        public displayDatePickerDialog: boolean = false;

        public currentRangeDisplayed: string|null = null;
        public rangesAvailable = [
            {
                value: 'resourceTimeGridDay',
                text: 'Jour',
            },
            {
                value: 'resourceTimeGridWeek',
                text: 'Semaine',
            },
            {
                value: 'dayGridMonth',
                text: 'Mois',
            },
        ];

        public isMoveEventLoading: boolean = false;
        public isResizeEventLoading: boolean = false;

        public businessHourToCreate: IBusinessHourToCreate | null = null;
        public eventToCreate: IEventToCreate | null = null;
        public eventToDetail: EventApi|null = null;

        public eventTooltipDialog: boolean = false;
        public eventTooltipStyle: IEventTooltipStyle | null = null;
        public eventTooltipTitle: string = '';
        public eventTooltipText: string|null = null;

        public cancelMoveEvent!: () => void;
        public confirmMoveEvent!: () => void;
        public cancelResizeEvent!: () => void;
        public confirmResizeEvent!: () => void;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @accountNamespace.Getter('displaySettings')
        public displaySettings!: IDisplaySettings;

        @agendaNamespace.Getter('agendasList')
        public agendasList!: IAgenda[];

        @agendaNamespace.Getter('selectedDateValue')
        public selectedDateValue!: string;

        @agendaNamespace.Getter('selectedViewValue')
        public selectedViewValue!: AgendaViewValue;

        @agendaNamespace.Action('selectDate')
        public selectDate!: (date: string) => void;

        @agendaNamespace.Action('selectView')
        public selectView!: (view: AgendaViewValue) => void;

        @businessHourNamespace.Getter('businessHoursList')
        public businessHoursList!: IBusinessHour[];

        @businessHourNamespace.Getter('requestStatus')
        public businessHourRequestStatus!: string|null;

        @eventNamespace.Getter('eventsList')
        public eventsList!: IEvent[];

        @eventNamespace.Getter('requestStatus')
        public eventRequestStatus!: string|null;

        @agendaNamespace.Getter('selectedAgendasList')
        public selectedAgendasList!: IAgenda[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @reasonNamespace.Getter('selectedReasonsList')
        public selectedReasonsList!: IReason[];

        @reasonNamespace.Getter('areAllReasonsSelected')
        public areAllReasonsSelected!: boolean;

        get selectedDate() {
            return this.selectedDateValue;
        }

        set selectedDate(val: any) {
            this.selectDate(val);
        }

        get selectedView() {
            return this.selectedViewValue;
        }

        set selectedView(val: any) {
            this.selectView(val);
        }

        get resources() {
            return this.selectedAgendasList.map((agenda: IAgenda) => {
                return {
                    id: agenda.id,
                    title: this.tooltipNameAgenga(agenda.name as string),
                    businessHours: {
                        startTime: '00:00',
                        endTime: '00:00',
                        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                    },
                };
            });
        }

        public tooltipNameAgenga(name: string) {
            switch (this.selectedAgendasList.length) {
                case 1:
                    return this.selectedAgendasList[0].name;
                case 2:
                    return name.substr(0, 5) + '...';
                default:
                    return name.match(/\b(\w)/g)!.join(".").toUpperCase() + '.';
            }
        }

        get events(): EventInput[] {
            return this.eventsList
                .filter((event: IEvent) => {
                    return !this.displaySettings.hide_cancelled || event.status !== 'cancelled';
                })
                .map((event: IEvent) => this.getFullcalendarEventFromTuvEvent(event))
            ;
        }

        get businessHours() {
            return this.businessHoursList.map(
                (businessHour: IBusinessHour) => this.getFullcalendarEventFromBusinessHour(businessHour),
            );
        }

        get isLoading() {
            return this.eventRequestStatus === 'loading' || this.businessHourRequestStatus === 'loading';
        }

        @Watch('selectedAgendasList')
        public onSelectedAgendasListChanged(newResources: IAgenda[], oldResources: IAgenda[]) {
            const toRemove = oldResources.filter((oldAgenda: IAgenda) => {
                return !newResources.some((newAgenda: IAgenda) => oldAgenda.id === newAgenda.id);
            });
            const toAdd = newResources.filter((newAgenda: IAgenda) => {
                return !oldResources.some((oldAgenda: IAgenda) => oldAgenda.id === newAgenda.id);
            });

            this.calendar.batchRendering(() => {
                toRemove.forEach((agenda: IAgenda) => {
                    const resource = this.calendar.getResourceById(agenda.id);

                    this.tooltipNameAgenga(agenda.name as string);
                    if (resource) {
                        resource.remove();
                    }
                });

                toAdd.forEach((agenda: IAgenda) => this.calendar.addResource({
                    id: agenda.id,
                    title: this.tooltipNameAgenga(agenda.name as string),
                    businessHours: {
                        startTime: '00:00',
                        endTime: '00:00',
                        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                    },
                } as any));
            });

            this.fetchEvents();
        }

        @Watch('selectedReasonsList')
        public onSelectedReasonsListChanged() {
            this.fetchEvents();
        }

        @Watch('$route')
        public onRouteChange() {
            this.batchClearEvents();
            this.setMode();
            this.fetchEvents();
        }

        @Watch('selectedDateValue')
        public onSelectedDateValueChanged() {
            this.calendar.gotoDate(this.selectedDateValue);
        }

        @Watch('selectedViewValue')
        public onSelectedViewValueChanged() {
            if (this.selectedViewValue) {
                this.calendar.changeView(this.selectedViewValue);
            }
        }

        @Watch('businessHoursList')
        public onBusinessHoursListChanged() {
            if (this.mode === 'business-hours') {
                this.rerenderEvents();
            } else {
                this.updateResourcesBusinessHours(this.businessHoursList);
            }
        }

        @Watch('eventsList')
        public onEventsListChanged() {
            if (this.mode === 'events') {
                this.rerenderEvents();
            }

            if (this.eventToDetail) {
                this.eventToDetail = this.calendar.getEventById(this.eventToDetail.id);
            }
        }

        @Watch('displaySettings')
        public onDisplaySettingsChanged(newSet: IDisplaySettings|null, oldSet: IDisplaySettings|null) {
            this.calendar.batchRendering(() => {
                this.calendar.setOption('slotMinTime', this.displaySettings.from);
                this.calendar.setOption('slotMaxTime', this.displaySettings.to);
                this.calendar.setOption('hiddenDays', this.displaySettings.days);
                this.calendar.setOption('slotDuration', this.displaySettings.grid);
            });
        }

        public setMode() {
            const routeName = this.$route.name as string;
            this.mode = routeName === 'home' ? 'events' : routeName;
        }

        public setView() {
            const view = this.$vuetify.breakpoint.smAndUp ? 'resourceTimeGridWeek' : 'resourceTimeGridDay';
            this.selectView(view);
        }

        public getCurrentRangeDisplayed() {
            if (!this.calendar) {
                return null;
            }
            const localStart = toLuxonDateTime(this.calendar.view.currentStart, this.calendar);

            if (this.calendar.view.type === 'resourceTimeGridDay') {
                return localStart.toLocaleString({weekday: 'short', month: 'short', day: '2-digit'});
            }

            if (this.calendar.view.type === 'dayGridMonth') {
                const monthEnd = localStart
                    .endOf('month')
                    .toLocaleString({month: 'long', day: '2-digit'})
                ;

                return `1 - ${monthEnd}`;
            }

            const localEnd = toLuxonDateTime(this.calendar.view.currentEnd, this.calendar);

            const format: LocaleOptions & DateTimeFormatOptions = {
                month: 'long',
                day: '2-digit',
            };

            if (localStart.hasSame(localEnd, 'month')) {
                return `${localStart.toLocaleString({day: '2-digit'})} - ${localEnd.toLocaleString(format)}`;
            }

            return `${localStart.toLocaleString(format)} - ${localEnd.toLocaleString(format)}`;
        }

        public datesSet() {
            const currentDate = toLuxonDateTime(this.calendar.currentData.currentDate, this.calendar)
                .toUTC()
                .toISODate()
            ;

            this.currentRangeDisplayed = this.getCurrentRangeDisplayed();
            this.selectDate(currentDate as string);
            this.fetchEvents();
        }

        public fetchEvents() {
            const start = this.calendar.view.currentStart;
            const end = this.calendar.view.currentEnd;

            switch (this.mode) {
                case 'events':
                    this.fetchTuvEvents(start, end);
                    break;

                case 'business-hours':
                    this.fetchBusinessHours(start, end);
                    break;
            }

            this.setMouseOverHour();
        }

        public rerenderEvents() {
            switch (this.mode) {
                case 'events':
                    this.calendar.batchRendering(() => {
                        this.clearEvents();
                        this.events.map((event: EventInput) => {
                            this.calendar.addEvent(event);
                        });
                    });
                    break;

                case 'business-hours':
                    this.calendar.batchRendering(() => {
                        this.clearEvents();
                        this.businessHours.map((businessHour: EventInput) => {
                            this.calendar.addEvent(businessHour);
                        });
                    });
                    break;
            }
        }

        public batchClearEvents() {
            this.calendar.batchRendering(() => {
                this.clearEvents();
            });
        }

        public clearEvents() {
            this.calendar.getEvents().map((event: EventApi) => event.remove());
        }

        public onClickCreateEvent() {
            const now = DateTime.utc().set({second: 0, millisecond: 0});
            const minutesToAdd = now.minute >= 30 ? 60 - now.minute : 30 - now.minute;
            const slotDuration = LuxonDuration.fromISOTime(this.displaySettings.grid);

            const start = now.plus({minutes: minutesToAdd});
            const end = start.plus(slotDuration);
            const agenda = this.selectedAgendasList[0];

            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };

            this.onSelectTuvEvent(start.toISO(formatOpts) as string, end.toISO(formatOpts) as string, agenda);
        }

        public onSelect(args: {
            start: Date;
            end: Date;
            startStr: string;
            endStr: string;
            allDay: boolean;
            resource?: any;
            jsEvent: MouseEvent | null;
            view: ViewApi;
        }) {
            const start = this.getISODateTimeFromDate(args.start);
            const end = this.getISODateTimeFromDate(args.end);
            const agenda = this.agendasList.find((el: IAgenda) => el.id === args.resource.id) as IAgenda;

            switch (this.mode) {
                case 'events':
                    this.onSelectTuvEvent(start as string, end as string, agenda);
                    break;

                case 'business-hours':
                    this.onSelectBusinessHour(start as string, end as string, agenda);
                    break;
            }

            this.calendar.unselect();
        }

        private getHeaderHeight(element: HTMLElement) {
            if (!element) {
                return 0;
            }

            const height = element.offsetHeight;

            const style = getComputedStyle(element, null);

            const marginTop = parseFloat(style.marginTop) || 0;
            const marginBottom = parseFloat(style.marginBottom) || 0;

            return height + marginTop + marginBottom;
        }

        private computeCalendarContentHeight() {
            const toolbar: HTMLElement = document.querySelector('.calendar-toolbar') as HTMLElement;

            return window.innerHeight - this.$vuetify.application.top - this.getHeaderHeight(toolbar);
        }

        private windowResize({view}: {view: ViewApi}) {
            this.calendar.setOption('height', this.computeCalendarContentHeight());
            this.calendar.setOption('contentHeight', this.computeCalendarContentHeight());
            this.setMouseOverHour();
        }

        private eventClassNames(args: EventContentArg) {
            switch (this.mode) {
                case 'events':
                    return this.tuvEventClassNames(args);

                case 'business-hours':
                    return this.businessHourClassNames(args);

                default:
                    return [];
            }
        }

        private eventContent(args: EventContentArg) {
            if (args.isMirror) {
                return;
            }

            switch (this.mode) {
                case 'events':
                    return this.tuvEventContent(args);

                case 'business-hours':
                    return this.businessHourContent(args);

                default:
                    return '';
            }
        }

        private mouseEnter(args: {
            event: EventApi;
            jsEvent: MouseEvent;
        }) {
            const positionLeft = args.jsEvent.clientX + 200 > window.innerWidth;
            const positionTop = args.jsEvent.clientY + 200 > window.innerHeight;

            this.eventTooltipStyle = {
                top: positionTop ? null : `${args.jsEvent.clientY + 15}px`,
                bottom: positionTop ? `${window.innerHeight - args.jsEvent.clientY}px` : null,
                left: positionLeft ? `${args.jsEvent.clientX - 215}px`: `${args.jsEvent.clientX + 15}px`,
            };

            switch (this.mode) {
                case 'events':
                    this.mouseEnterTuvEvent(args.event);
                    break;

                case 'business-hours':
                    this.mouseEnterBusinessHour(args.event);
                    break;
            }

            this.eventTooltipDialog = true;
        }

        private mouseLeave() {
            this.eventTooltipDialog = false;
        }

        private mouseMove(event: MouseEvent): void {
            const positionLeft = event.clientX + 200 > window.innerWidth;
            const positionTop = event.clientY > (window.innerHeight / 2);

            this.eventTooltipStyle = {
                top: positionTop ? null : `${event.clientY + 15}px`,
                bottom: positionTop ? `${window.innerHeight - event.clientY}px` : null,
                left: positionLeft ? `${event.clientX - 215}px`: `${event.clientX + 15}px`,
            };
        }

        private eventDrop(args: {
            el: HTMLElement;
            event: EventApi;
            oldEvent: EventApi;
            delta: Duration;
            revert: () => void;
            jsEvent: Event;
            view: ViewApi;
        }) {
            if (
                args.event.extendedProps.event &&
                args.event.extendedProps.event.type === 'booking'
            ) {
                this.showMoveEventDialog(args.event, args.revert);
            } else {
                args.revert();
            }
        }

        private eventResize(args: {
            el: HTMLElement;
            startDelta: Duration;
            endDelta: Duration;
            oldEvent: EventApi;
            event: EventApi;
            revert: () => void;
            jsEvent: Event;
            view: ViewApi;
        }) {
            if (
                args.event.extendedProps.event &&
                args.event.extendedProps.event.type === 'booking'
            ) {
                this.showResizeEventDialog(args.event, args.revert);
            } else {
                args.revert();
            }
        }

        private eventClick(args: {
            el: HTMLElement;
            event: EventApi;
            jsEvent: MouseEvent;
            view: ViewApi;
        }) {
            switch (this.mode) {
                case 'events':
                    this.tuvEventClick(args.event);
                    break;

                case 'business-hours':
                    this.businessHourClick(args.event);
                    break;
            }
        }

        private showMoveEventDialog(event: EventApi, revert: () => void) {
            this.cancelMoveEvent = () => {
                revert();
                this.moveEventDialog = false;
            };

            this.confirmMoveEvent = () => {
                const eventModule = getModule(EventModule, this.$store);
                const snackbarModule = getModule(SnackbarModule, this.$store);

                const start = this.getISODateTimeFromDate(event.start as Date);
                const end = this.getISODateTimeFromDate(event.end as Date);

                this.isMoveEventLoading = true;

                eventModule
                    .moveEvent({
                        organization_id: this.loggedOrganization.id,
                        event_id: event.id,
                        agenda_id: event.getResources()[0].id,
                        start: start as string,
                        end: end as string,
                    })
                    .then((updatedEvent: IEvent) => {
                        snackbarModule.displaySuccess('Votre rendez-vous a bien été déplacé !');
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                        revert();
                    })
                    .finally(() => {
                        this.moveEventDialog = false;
                        this.isMoveEventLoading = false;
                    })
                ;
            };

            this.moveEventDialog = true;
        }

        private showResizeEventDialog(event: EventApi, revert: () => void) {
            this.cancelResizeEvent = () => {
                revert();
                this.resizeEventDialog = false;
            };

            this.confirmResizeEvent = () => {
                const eventModule = getModule(EventModule, this.$store);
                const snackbarModule = getModule(SnackbarModule, this.$store);

                const start = this.getISODateTimeFromDate(event.start as Date);
                const end = this.getISODateTimeFromDate(event.end as Date);

                this.isResizeEventLoading = true;

                eventModule
                    .moveEvent({
                        organization_id: this.loggedOrganization.id,
                        event_id: event.id,
                        agenda_id: event.getResources()[0].id,
                        start: start as string,
                        end: end as string,
                    })
                    .then((updatedEvent: IEvent) => {
                        snackbarModule.displaySuccess('Votre rendez-vous a bien été modifié !');
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                        revert();
                    })
                    .finally(() => {
                        this.resizeEventDialog = false;
                        this.isResizeEventLoading = false;
                    })
                ;
            };

            this.resizeEventDialog = true;
        }

        private renderCalendar() {
            this.calendar = new Calendar(
                this.$refs.calendar as HTMLElement,
                {
                    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
                    datesAboveResources: true,
                    plugins: [
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin,
                        luxonPlugin,
                        resourceTimeGridPlugin,
                        rrulePlugin,
                    ],
                    allDaySlot: false,
                    headerToolbar: false,
                    resources: this.resources,
                    select: this.onSelect,
                    eventClassNames: this.eventClassNames,
                    eventContent: this.eventContent,
                    eventMouseEnter: this.mouseEnter,
                    eventMouseLeave: this.mouseLeave,
                    eventDrop: this.eventDrop,
                    eventResize: this.eventResize,
                    eventClick: this.eventClick,
                    datesSet: this.datesSet,
                    resourceOrder: 'title',
                    initialView: this.selectedView,
                    nowIndicator: true,
                    selectable: true,
                    selectMirror: true,
                    locales: [frLocale],
                    locale: 'fr',
                    timeZone: this.loggedOrganization.timezone,
                    height: this.computeCalendarContentHeight(),
                    contentHeight: this.computeCalendarContentHeight(),
                    windowResize: this.windowResize,
                    expandRows: true,
                    slotMinTime: this.displaySettings.from,
                    slotMaxTime: this.displaySettings.to,
                    hiddenDays: this.displaySettings.days,
                    slotDuration: this.displaySettings.grid,
                    editable: true,
                    eventResourceEditable: true,
                    eventResizableFromStart: true,
                    eventStartEditable: true,
                    longPressDelay: 100,
                    weekNumbers: true,
                    slotLabelFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        omitZeroMinute: false,
                    },
                },
            );

            this.calendar.render();
        }

        private setMouseOverHour() {
            // Clear previous indicators
            document
                .querySelectorAll('.tuv-time-indicator')
                .forEach((val) => {
                    val.parentElement?.removeChild(val);
                })
            ;

            if(this.selectedAgendasList.length > 6) {
                return;
            }

            if (this.calendar?.view.type !== 'resourceTimeGridWeek') {
                return;
            }

            const cal = document.querySelectorAll('.fc-timegrid-slots tbody tr');
            const days = document.querySelectorAll('.fc-timegrid-now-indicator-container');

            days.forEach((elm, it): void => {
                if (it === 0) {
                    return;
                }

                for (const hours of cal) {
                    const hour: string | null = hours.children[0].getAttribute('data-time');
                    const btn: HTMLElement = document.createElement('div');
                    if (!hour) return;
                    
                    btn.classList.add('tuv-time-indicator', this.mode);
                    btn.style.height = hours.getBoundingClientRect().height + 'px';
                    btn.innerHTML = hour.substring(0, hour.length-3);
                    elm.appendChild(btn.cloneNode(true));
                }
            });
        }

        // LIFECYCLE HOOKS

        private created() {
            this.setMode();
        }

        private mounted() {
            this.setView();
            this.renderCalendar();
            this.fetchEvents();
        }

        // TUV EVENT FUNCTIONS

        private fetchTuvEvents(start: Date, end: Date) {
            if (this.selectedAgendasList.length === 0) {
                return;
            }

            const from = this.getISODateTimeFromDate(start);
            const to = this.getISODateTimeFromDate(end);
            
            const eventsParams: IListEventsParams = {
                organization_id: this.loggedOrganization.id,
                from: from as string,
                to: to as string,
                agendas: this.selectedAgendasList.filter((agenda: IAgenda) => typeof agenda.id === 'string').map((agenda: IAgenda) => agenda.id as string),
                hide_cancelled: this.displaySettings.hide_cancelled,
            };

            if (!this.areAllReasonsSelected) {
                eventsParams.reasons = this.selectedReasonsList.map((reason: IReason) => reason.id);
            }

            const businessHoursParams: IListBusinessHoursParams = {
                organization_id: this.loggedOrganization.id,
                from: from as string,
                to: to as string,
                agendas: this.selectedAgendasList.filter((agenda: IAgenda) => typeof agenda.id === 'string').map((agenda: IAgenda) => agenda.id as string),
            };

            getModule(EventModule, this.$store)
                .fetchEvents(eventsParams)
            ;

            getModule(BusinessHourModule, this.$store)
                .fetchBusinessHours(businessHoursParams)
            ;
        }

        private updateResourcesBusinessHours(businessHours: IBusinessHour[]) {
            const start = this.calendar.view.currentStart;
            const end = this.calendar.view.currentEnd;

            this.calendar.batchRendering(() => {
                this.calendar
                    .getResources()
                    .forEach((resource: ResourceApi) => {
                        let resourceBusinessHours =
                            businessHours
                            .reduce((acc: any[], businessHour: IBusinessHour) => {
                                if (businessHour.agenda.id !== resource.id) {
                                    return acc;
                                }

                                acc.push(
                                    rruleHelper.extractFullCalendarBusinessHours(businessHour, start, end),
                                );

                                return acc;
                            }, [])
                        ;

                        if (resourceBusinessHours.length === 0) {
                            resourceBusinessHours = [{
                                startTime: '00:00',
                                endTime: '00:00',
                                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                            }];
                        }

                        const updatedResource = {
                            id: resource.id,
                            title: this.tooltipNameAgenga(resource.title),
                            businessHours: resourceBusinessHours,
                        };

                        resource.remove();
                        this.calendar.addResource(updatedResource);
                    })
                ;
            });
        }

        private getFullcalendarEventFromTuvEvent(event: IEvent): EventInput {
            const colors = this.getTuvEventColors(event);

            const fullcalendarEvent: EventInput = {
                id: event.id,
                resourceIds: event.agenda.id ? [event.agenda.id] : [],
                backgroundColor: colors.backgroundColor,
                borderColor: colors.borderColor,
                textColor: 'rgba(17, 36, 69, 1)',
                editable: event.type === 'booking',
                event,
            };

            if (event.recurrence) {
                const start = DateTime.fromISO(event.start);
                const end = DateTime.fromISO(event.end);
                let rrule: any;
                let exdate: any;

                [rrule, exdate] = rruleHelper.parseRuleForFullcalendar(event.recurrence, event.timezone);

                fullcalendarEvent.rrule = rrule;
                fullcalendarEvent.exdate = exdate;
                fullcalendarEvent.duration = end.diff(start).milliseconds;
            } else {
                fullcalendarEvent.start = event.start;
                fullcalendarEvent.end = event.end;
            }

            return fullcalendarEvent;
        }

        private tuvEventClassNames(args: EventContentArg) {
            const tuvEvent: IEvent = args.event.extendedProps.event;

            if (args.isMirror) {
                return ['tuv-mirror-event'];
            }

            if (!tuvEvent) {
                return [];
            }

            if (tuvEvent.type === 'booking') {
                return [
                    'tuv-event',
                    `attendance-${(tuvEvent as IBooking).booking.attendance}`,
                    `status-${tuvEvent.status}`,
                ];
            } else if (tuvEvent.type === 'unavailability') {
                return ['tuv-event', 'tuv-unavailability'];
            }

            return ['tuv-event'];
        }

        private getTuvEventColors(event: IEvent) {
            switch (event.type) {
                case 'booking':
                    const reasonId = (event as IBooking).booking.reason.id;
                    let color = '#5C5C5C';

                    this.selectedReasonsList.forEach((reason: IReason) => {
                        if (reason.id === reasonId) {
                            color = reason.color;
                        }
                    });

                    return {
                        backgroundColor: lightenDarkenColor(color, 80),
                        borderColor: color,
                    };

                case 'unavailability':
                    return {
                        backgroundColor: 'white',
                        borderColor: 'var(--v-error-base)',
                    };

                default:
                    return {
                        backgroundColor: lightenDarkenColor('#5C5C5C', 80),
                        borderColor: '#5C5C5C',
                    };
            }
        }

        private onSelectTuvEvent(start: string, end: string, agenda: IAgenda) {
            this.eventToCreate = {
                start: start as string,
                end: end as string,
                agenda,
            };

            this.createEventDialog = true;
        }

        private tuvEventContent(args: EventContentArg) {
            const tuvEvent: IEvent = args.event.extendedProps.event;

            if (!tuvEvent) {
                return false;
            }

            switch (tuvEvent.type) {
                case 'booking':
                    return this.tuvEventContentBooking(tuvEvent as IBooking);

                case 'unavailability':
                    return this.tuvEventContentUnavailability(tuvEvent as IUnavailability);
            }
        }

        private tuvEventContentBooking(tuvEvent: IBooking) {
            const client = tuvEvent.booking.client;
            const start = DateTime
                .fromISO(tuvEvent.start)
                .toLocaleString(DateTime.TIME_SIMPLE)
            ;

            let scheduledProcedure = '';

            if (tuvEvent.booking.type === 'scheduled_procedure') {
                scheduledProcedure = '<span class="error white--text caption pa-1 ml-2">H</span>';
            }

            let referent = '';

            if (tuvEvent.booking.referent.id) {
                referent = `<b class="rounded pa-1"> 👥</b>`;
            }

            return {html: `
                <p class="event-client mb-0">
                    <span class="fc-time">${start}</span>
                    <span class="font-weight-bold">${client.last_name}</span>
                    <span>${client.first_name ?? ''}</span>
                    ${scheduledProcedure}
                    ${referent}
                </p>
                <p class="event-animal mb-0">
                    ${tuvEvent.booking.animal ? this.getAnimalLine(tuvEvent.booking.animal) : ''}
                </p>
                ${this.getSourceIndicator(tuvEvent.booking.source)}
            `};
        }

        private tuvEventContentUnavailability(tuvEvent: IUnavailability) {
            let html = `<p class="event-unavailability text-uppercase mb-0">${tuvEvent.unavailability.object}</p>`;

            if (tuvEvent.unavailability.comment) {
                const trimmedComment: string = this.trimSentence(tuvEvent.unavailability.comment, 80);

                html += `<div class="event-unavailability-com font-italic">${trimmedComment}</div>`;
            }

            return {html};
        }

        private trimSentence(sentence: string, nbMax: number) {
            let sanitizeSentence: string = sentence.substring(0, nbMax);

            if (sentence.length > nbMax) {
                if (sanitizeSentence.charAt(sanitizeSentence.length-1) !== '') {
                    sanitizeSentence = sanitizeSentence+'...';
                } else {
                    sanitizeSentence = sanitizeSentence+' ...';
                }
            }

            return sanitizeSentence;
        }

        private tuvEventClick(event: EventApi) {
            switch (event.extendedProps.event.type) {
                case 'booking':
                    return this.eventClickBooking(event);

                case 'unavailability':
                    return this.eventClickUnavailability(event);
            }
        }

        private eventClickBooking(event: EventApi) {
            this.eventToDetail = event;
            this.bookingDetailsDialog = true;
        }

        private eventClickUnavailability(event: EventApi) {
            this.eventToDetail = event;
            this.unavailabilityDetailsDialog = true;
        }

        private mouseEnterTuvEvent(event: EventApi) {
            const tuvEvent = event.extendedProps.event;

            switch (tuvEvent.type) {
                case 'booking':
                    this.mouseEnterBooking(tuvEvent);
                    break;

                case 'unavailability':
                    this.mouseEnterUnavailability(tuvEvent);
                    break;

                case 'scheduled_procedure':
                    this.mouseEnterHospitalization(tuvEvent);
                    break;
            }
        }

        private mouseEnterBooking(tuvEvent: IBooking) {
            const start = DateTime.fromISO(tuvEvent.start).toLocaleString(DateTime.TIME_SIMPLE);
            const end = DateTime.fromISO(tuvEvent.end).toLocaleString(DateTime.TIME_SIMPLE);
            const client = tuvEvent.booking.client;

            this.eventTooltipTitle = `${tuvEvent.booking.reason.name} - ${tuvEvent.agenda.name}`;
            this.eventTooltipText = `${start} - ${end} <br>`;
            this.eventTooltipText += `${client.last_name} ${client.first_name ?? ''} <br>`;

            if (tuvEvent.booking.referent.id) {
                this.eventTooltipText += ` 👥 Dr. ${tuvEvent.booking.referent.first_name} ${tuvEvent.booking.referent.last_name} <br>`;
            }

            if (tuvEvent.booking.animal) {
                this.eventTooltipText += this.getAnimalLine(tuvEvent.booking.animal);
            }
            if (tuvEvent.booking.type === 'scheduled_procedure') {
                let startDate = '';
                let startTime = '';
                let deposit = '';

                if (tuvEvent.booking.deposit_date) {
                    startDate = DateTime.fromISO(tuvEvent.booking.deposit_date)
                    .toLocaleString({weekday: 'long', day: 'numeric', month: 'long'});
                    startTime = DateTime.fromISO(tuvEvent.booking.deposit_date)
                    .toLocaleString(DateTime.TIME_SIMPLE) as string;
                    deposit = startDate + ' à ' + startTime;
    
                    this.eventTooltipText += `<div class="pa-1 mt-1 mb-1 hospitwrap"><span class="caption text-uppercase font-weight-bold">Dépôt de l\'animal</span><br>${deposit}</div>`;
                }

            }

            if (tuvEvent.notes.length > 0) {
                this.eventTooltipText += `<div class="pa-1 mt-1 pins postit">${tuvEvent.notes.join('<br>')}</div>`;
            }
        }

        private mouseEnterUnavailability(tuvEvent: IUnavailability) {
            this.eventTooltipTitle = `${tuvEvent.unavailability.object} - ${tuvEvent.agenda.name}`;
            this.eventTooltipText = tuvEvent.unavailability.comment ? tuvEvent.unavailability.comment : null;
        }

        private mouseEnterHospitalization(tuvEvent: IHospitalization) {
            this.eventTooltipTitle = `${tuvEvent.hospitalization.object} - ${tuvEvent.agenda.name}`;
            this.eventTooltipText = tuvEvent.hospitalization.comment ? tuvEvent.hospitalization.comment : null;
        }

        private getAnimalLine(animal: IAnimal|null) {
            if (!animal) {
                return '';
            }

            const animalBlocks = [
                animal.name,
            ];

            if (animal.species) {
                animalBlocks.push(animal.species.name);
            }

            if (animal.birth_date) {
                animalBlocks.push((this.$options.filters as any).age(animal.birth_date));
            }

            return animalBlocks.join(', ');
        }

        private getSourceIndicator(source: string) {
            if (source === 'client') {
                return '<div class="event-source">@</div>';
            }

            return '';
        }


        // BUSINESS HOURS FUNCTIONS

        private fetchBusinessHours(start: Date, end: Date) {
            if (this.selectedAgendasList.length === 0) {
                return;
            }

            if (this.selectedReasonsList.length === 0) {
                return;
            }

            const from = this.getISODateTimeFromDate(start);
            const to = this.getISODateTimeFromDate(end);

            const params: IListBusinessHoursParams = {
                organization_id: this.loggedOrganization.id,
                from: from as string,
                to: to as string,
                agendas: this.selectedAgendasList.filter((agenda: IAgenda) => typeof agenda.id === 'string').map((agenda: IAgenda) => agenda.id as string),
            };

            this.calendar.batchRendering(() => {
                this.calendar
                    .getResources()
                    .forEach((resource: ResourceApi) => {
                        const updatedResource = {
                            id: resource.id,
                            title: this.tooltipNameAgenga(resource.title),
                            businessHours: {
                                startTime: '00:00',
                                endTime: '00:00',
                                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                            },
                        };

                        resource.remove();
                        this.calendar.addResource(updatedResource);
                    })
                ;
            });

            getModule(BusinessHourModule, this.$store)
                .fetchBusinessHours(params)
            ;
        }

        private getFullcalendarEventFromBusinessHour(businessHour: IBusinessHour) {
            const fullcalendarEvent: EventInput = {
                id: businessHour.id,
                backgroundColor: 'white',
                borderColor: '#eee',
                textColor: 'rgba(17, 36, 69, 0.6)',
                classNames: 'tuv-business-hour',
                editable: false,
                resourceIds: businessHour.agenda.id ? [businessHour.agenda.id] : [],
                businessHour,
            };

            if (businessHour.recurrence) {
                const start = DateTime.fromISO(businessHour.start);
                const end = DateTime.fromISO(businessHour.end);
                let rrule: any;
                let exdate: any;

                [rrule, exdate] = rruleHelper.parseRuleForFullcalendar(businessHour.recurrence, businessHour.timezone);

                fullcalendarEvent.rrule = rrule;
                fullcalendarEvent.exdate = exdate;
                fullcalendarEvent.duration = end.diff(start).milliseconds;
            } else {
                fullcalendarEvent.start = businessHour.start;
                fullcalendarEvent.end = businessHour.end;
            }

            return fullcalendarEvent;
        }

        private onSelectBusinessHour(start: string, end: string, agenda: IAgenda) {
            this.businessHourToCreate  = {
                start: start as string,
                end: end as string,
                agenda,
                reasons: [],
            };

            this.createBusinessHourDialog = true;
        }

        private businessHourClassNames(args: EventContentArg) {
            if (args.isMirror) {
                return ['tuv-mirror-business-hour'];
            }

            return [];
        }

        private businessHourContent(args: EventContentArg) {
            const businessHour: IBusinessHour = args.event.extendedProps.businessHour;
            if (!businessHour) {
                return false;
            }

            const start = DateTime.fromISO(businessHour.start).toLocaleString(DateTime.TIME_SIMPLE);
            const end = DateTime.fromISO(businessHour.end).toLocaleString(DateTime.TIME_SIMPLE);

            const reasons = businessHour.reasons.map((reason: IReason) => `
                <span class="ma-1 v-chip v-chip--no-color theme--light v-size--small">
                    <span class="v-chip__content">${reason.name}</span>
                </span>
            `);

            let recurrenceInfo  = '';

            if (businessHour.recurrence) {
                recurrenceInfo = `
                    <div class="v-alert v-sheet theme--dark v-alert--border v-alert--dense info pa-1 ma-1">
                        <div class="v-alert__wrapper">
                            <div class="v-alert__content caption">
                                ${rruleHelper.getTextRepresentation(businessHour.recurrence as string)}
                            </div>
                        </div>
                    </div>
                `;
            }

            return {html: `
                <p class="event-client mb-0">
                    <span class="fc-time">${start} - ${end}</span>
                </p>
                ${recurrenceInfo}
                <p>
                    ${reasons.join('')}
                </p>
            `};
        }

        private businessHourClick(event: EventApi) {
            this.eventToDetail = event;
            this.businessHourDetailsDialog = true;
        }

        private mouseEnterBusinessHour(event: EventApi) {
            const businessHour = event.extendedProps.businessHour;

            this.eventTooltipTitle = `${businessHour.reasons.length} motif(s) réservable(s) en ligne`;
            this.eventTooltipText = null;
        }


        // HELPERS

        private getISODateTimeFromDate(date: Date) {
            const formatOpts = {
                suppressMilliseconds: true,
            };

            return toLuxonDateTime(date, this.calendar).toUTC().toISO(formatOpts);
        }
    }
