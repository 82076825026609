
    import {rrulestr, RRuleSet} from 'rrule';
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime, ToISOTimeOptions} from 'luxon';

    import {SnackbarModule, EventModule} from '@/store/modules';

    import {
        IOrganization,
        IUnavailability,
    } from '@/types';

    import {rruleHelper} from '@/helpers';

    const organizationNamespace = namespace('organization');

    @Component<DeleteUnavailablityDialog>({})
    export default class DeleteUnavailablityDialog extends Vue {
        public isDeletingSingleOccurrence: boolean = false;
        public isDeletingNextOccurrences: boolean = false;
        public isDeletingAllOccurrences: boolean = false;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Prop({
            type: Object,
            required: true,
        })
        private unavailability!: IUnavailability;

        @Prop({
            type: Date,
            required: true,
        })
        private occurrence!: Date;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private openedSync!: boolean;

        get occurrenceDate() {
            return DateTime
                .fromJSDate(this.occurrence, {zone: this.unavailability.timezone})
                .toLocaleString(DateTime.DATE_FULL)
            ;
        }

        get isSubmitting() {
            return this.isDeletingSingleOccurrence ||
                this.isDeletingNextOccurrences ||
                this.isDeletingAllOccurrences
            ;
        }

        private deleteSingleOccurrence() {
            this.isDeletingSingleOccurrence = true;

            const recurrence: RRuleSet =
                rrulestr(this.unavailability.recurrence as string, {forceset: true}) as RRuleSet;

            recurrence.exdate(
                rruleHelper.formatFullcalendarDateForRRule(this.occurrence, this.unavailability.timezone),
            );

            const start = DateTime.fromISO(this.unavailability.start);
            const end = DateTime.fromISO(this.unavailability.end);
            const duration = end.diff(start).milliseconds;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .updateRecurrence({
                    organization_id: this.loggedOrganization.id,
                    event_id: this.unavailability.id,
                    recurrence: recurrence.toString(),
                    duration,
                })
                .then(() => {
                    snackbarModule.displaySuccess('Vos indisponibilités ont bien été supprimées !');
                    this.$emit('deleted');
                    this.openedSync = false;
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isDeletingSingleOccurrence = false)
            ;
        }

        private deleteNextOccurrences() {
            this.isDeletingNextOccurrences = true;

            const formatOpts: ToISOTimeOptions = {
                format: 'basic',
                includeOffset: false,
                suppressMilliseconds: true,
            };

            const until = DateTime
                .fromJSDate(this.occurrence, {zone: this.unavailability.timezone})
                .startOf('day')
                .toISO(formatOpts)
            ;

            const recurrence = rruleHelper.setUntil(this.unavailability.recurrence as string, until as string);

            const start = DateTime.fromISO(this.unavailability.start);
            const end = DateTime.fromISO(this.unavailability.end);
            const duration = end.diff(start).milliseconds;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .updateRecurrence({
                    organization_id: this.loggedOrganization.id,
                    event_id: this.unavailability.id,
                    recurrence,
                    duration,
                })
                .then(() => {
                    snackbarModule.displaySuccess('Vos indisponibilités ont bien été supprimées !');
                    this.$emit('deleted');
                    this.openedSync = false;
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isDeletingNextOccurrences = false)
            ;
        }

        private deleteAllOccurrences() {
            this.isDeletingAllOccurrences = true;

            const eventModule = getModule(EventModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            eventModule
                .deleteEvent({
                    organization_id: this.loggedOrganization.id,
                    event_id: this.unavailability.id,
                })
                .then(() => {
                    snackbarModule.displaySuccess('Vos indisponibilités ont bien été supprimées !');
                    this.$emit('deleted');
                    this.openedSync = false;
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isDeletingAllOccurrences = false)
            ;
        }
    }
