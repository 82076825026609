
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {DateTime} from 'luxon';

    import {EventApi} from '@fullcalendar/common';

    import {
        IBusinessHour,
    } from '@/types';

    import BusinessHourFormCard from '@/components/BusinessHourFormCard.vue';
    import DeleteBusinessHourDialog from '@/components/dialogs/DeleteBusinessHourDialog.vue';

    import {rruleHelper} from '@/helpers';

    @Component<BusinessHourCard>({
        components: {
            BusinessHourFormCard,
            DeleteBusinessHourDialog,
        },
    })
    export default class BusinessHourCard extends Vue {
        public businessHourFormIsOpen: boolean = false;
        public deleteDialog: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public value!: EventApi;

        get agenda() {
            return this.businessHour.agenda.name;
        }

        get startDateTimeObject() {
            return DateTime.fromJSDate(this.value.start as Date, {zone: this.businessHour.timezone});
        }

        get endDateTimeObject() {
            return DateTime.fromJSDate(this.value.end as Date, {zone: this.businessHour.timezone});
        }

        get startDate() {
            return this.startDateTimeObject.toLocaleString({weekday: 'long', day: 'numeric', month: 'long'});
        }

        get startTime() {
            return this.startDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get endTime() {
            return this.endDateTimeObject.toLocaleString(DateTime.TIME_SIMPLE);
        }

        get businessHour(): IBusinessHour {
            return this.value.extendedProps.businessHour;
        }

        // tslint:disable-next-line:no-empty
        set businessHour(val) {}

        get recurrenceText() {
            if (!this.businessHour.recurrence) {
                return null;
            }

            return rruleHelper.getTextRepresentation(this.businessHour.recurrence);
        }

        public closeAll() {
            this.businessHourFormIsOpen = false;
            this.$emit('close');
        }
    }
