
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IBookingForm,
        IEvent,
        IOrganization,
        IAgenda,
        IClient,
        IAnimal,
        IReason,
        IUnavailabilityForm,
        ICreateUnavailabilityParams,
        ICreateBookingParams,
        IHospitalizationForm,
        ICreateHospitalizationParams,
        IHospitalization,
    } from '@/types';

    import BookingForm from '@/components/BookingForm.vue';
    import UnavailabilityForm from '@/components/UnavailabilityForm.vue';
    import HospitalizationForm from '@/components/HospitalizationForm.vue';

    import {EventModule, SnackbarModule} from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<CreateEventCard>({
        components: {
            BookingForm,
            UnavailabilityForm,
            HospitalizationForm,
        },
    })
    export default class CreateEventCard extends Vue {
        public tab: number | null = null;
        public dialog: boolean = true;
        public isSubmittingEvent: boolean = false;
        public bookingFormValid: boolean = false;
        public unavailabilityFormValid: boolean = false;
        public booking!: IBookingForm;
        public unavailability!: IUnavailabilityForm;
        public hospitalization!: IHospitalizationForm;
        public hospitalizationFromValid: boolean = false;

        @Prop({
            type: Object,
            required: true,
        })
        public value!: IEvent;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get bookingParams(): ICreateBookingParams {
            const params: ICreateBookingParams = {
                organization_id: this.loggedOrganization.id,
                agenda_id: (this.booking.agenda as IAgenda).id,
                client_id: (this.booking.client as IClient).id,
                reason_id: (this.booking.reason as IReason).id,
                start: this.booking.start,
                end: this.booking.end,
            };

            if (this.booking.animal) {
                params.animal_id = (this.booking.animal as IAnimal).id;
            }

            if (this.booking.private_comment) {
                params.private_comment = this.booking.private_comment;
            }

            if (this.booking.public_comment) {
                params.public_comment = this.booking.public_comment;
            }

            return params;
        }

        get hospitalizationParams(): ICreateHospitalizationParams {
            const params: ICreateHospitalizationParams = {
                organization_id: this.loggedOrganization.id,
                agenda_id: (this.hospitalization.agenda as IAgenda).id,
                client_id: (this.hospitalization.client as IClient).id,
                reason_id: (this.hospitalization.reason as IReason).id,
                type: 'scheduled_procedure',
                start: this.hospitalization.start,
                end: this.hospitalization.end,
                deposit_date: this.hospitalization.startDeposit,
                private_comment: this.hospitalization.private_comment,
            };

            if (this.hospitalization.animal) {
                params.animal_id = (this.hospitalization.animal as IAnimal).id;
            }

            if (this.hospitalization.private_comment) {
                params.private_comment = this.hospitalization.private_comment;
            }

            return params;
        }

        get unavailabilityParams(): ICreateUnavailabilityParams {
            const params: ICreateUnavailabilityParams = {
                organization_id: this.loggedOrganization.id,
                agenda_id: (this.unavailability.agenda as IAgenda).id,
                object: this.unavailability.object,
                comment: this.unavailability.comment,
            };

            if (this.unavailability.recurrence) {
                const start = DateTime.fromISO(this.unavailability.start);
                const end = DateTime.fromISO(this.unavailability.end);

                params.recurrence = this.unavailability.recurrence;
                params.duration = end.diff(start).milliseconds;
            } else {
                params.start = this.unavailability.start;
                params.end = this.unavailability.end;
            }

            return params;
        }

        get isValidatedForm() {
            if (this.tab === 0) {
                return !this.bookingFormValid;
            }

            if (this.tab === 1) {
                return !this.unavailabilityFormValid;
            }

            if (this.tab === 2) {
                return !this.hospitalizationFromValid;
            }
        }

        public submitButtonClicked() {
            if (this.tab === 0) {
                return this.submitBooking();
            }

            if (this.tab === 1) {
                return this.submitUnavailability();
            }

            if (this.tab === 2) {
                return this.submitHospitalization();
            }


        }

        public submitBooking() {
            this.isSubmittingEvent = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(EventModule, this.$store)
                .createBooking(this.bookingParams)
                .then((booking: IEvent) => {
                    snackbarModule.displaySuccess('Votre rendez-vous a bien été créé !');
                    this.$emit('created', booking);
                    this.$emit('close');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingEvent = false)
            ;
        }

        public submitHospitalization() {
            this.isSubmittingEvent = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(EventModule, this.$store)
                .createHospitalization(this.hospitalizationParams)
                .then((booking: IEvent) => {
                    snackbarModule.displaySuccess('L\'hospitalization a bien été créé !');
                    this.$emit('created', booking);
                    this.$emit('close');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingEvent = false)
            ;
        }

        public submitUnavailability() {
            this.isSubmittingEvent = true;

            const snackbarModule = getModule(SnackbarModule, this.$store);

            getModule(EventModule, this.$store)
                .createUnavailability(this.unavailabilityParams)
                .then((unavailability: IEvent) => {
                    snackbarModule.displaySuccess('Votre indisponibilité a bien été créé !');
                    this.$emit('created', unavailability);
                    this.$emit('close');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.isSubmittingEvent = false)
            ;
        }

        public created() {
            this.booking = Object.assign({}, {
                animal: null,
                client: null,
                reason: null,
                public_comment: null,
                private_comment: null,
            }, this.value);

            (this.unavailability as {comment: null}) = Object.assign({
                comment: null,
            }, this.value);

            (this.hospitalization  as {comment: null}) = Object.assign({
                comment: null,
                type: '',
                private_comment: '',
                deposit_date: '',
                start_deposit: '',
            }, this.value);
        }
    }
