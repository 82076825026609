
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Model, Prop} from 'vue-property-decorator';

@Component<IbanInput>({})
export default class IbanInput extends Vue {
    public innerModel: stripe.elements.Element|null = null;

    @Prop({
        type: Object,
        required: true,
    })
    public stripeModule!: stripe.Stripe;

    @Model('stripeCard', {type: Object})
    public readonly value!: stripe.elements.Element|null;

    public createStripeIbanField() {
        const elements = this.stripeModule.elements();

        const style = {
          base: {
            color: 'black',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        };

        const options = {
            style,
        };

        this.innerModel = elements.create('card', options);
        this.innerModel.mount('#card-element');
        const el = document.querySelector<HTMLElement>('#card-element iframe');
        if (el) {
            el.style.width = '';
        }
        this.onCardCreated();

        this.innerModel.on('change', (event: any) => {
            this.onCardChange(event);
        });
    }

    public mounted() {
        this.createStripeIbanField();
    }

    @Emit('stripeCard')
    private onCardCreated() {
        return this.innerModel;
    }

    @Emit('change')
    private onCardChange(event: any) {
        return event;
    }

}
